import { hasValue } from "@xxl/common-utils";
import type {
  ElevateApi,
  LandingPageQuery,
  LandingPageRequestSettings,
} from "@xxl/product-search-api";
import { clientAndServerApiFunctionFactory } from "../../../shared/helpers";
import type { ApiOptions } from "../../../shared/types";
import { elevateApiInstance } from "../api-instance-util";

export type GetLandingPageApiOptions = ApiOptions<
  ElevateApi<unknown>["storefront"]["landingPage"]
>;

export const getLandingPageResults =
  ({
    options,
    toggle_elevate_cluster_landing_page,
  }: {
    options: GetLandingPageApiOptions;
    toggle_elevate_cluster_landing_page: boolean;
  }) =>
  async (
    query: LandingPageQuery,
    includeNavigation: boolean,
    initialData?: LandingPageRequestSettings
  ) => {
    const apiFunction = clientAndServerApiFunctionFactory(
      options,
      () =>
        elevateApiInstance(toggle_elevate_cluster_landing_page).storefront
          .landingPage
    );

    return apiFunction(query, {
      navigation: {
        include: Boolean(includeNavigation),
      },
      ...(hasValue(initialData) && {
        primaryList: initialData.primaryList,
      }),
    });
  };
